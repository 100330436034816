import React, {useEffect, useState} from 'react'
import {Table, TableHead, TableRow, TableCell, TableBody, Card, Typography, CardContent, Paper, CardActions} from "@material-ui/core";
import {deleteStandard, findAllInternalCodes, findStandardById} from "../../api/StandardApi";
import { navigate } from "gatsby";

import Header from "../common/Header";
import ButtonStyled from "../common/ButtonStyled";

import { useStyles } from "../../styles/CommonStyles";
import { useTranslation } from 'react-i18next';
import ConfirmDialog from "../common/ConfirmDialog";

interface StandardDetailProps{
    id: number,
    style?:any,
}

export default function StandardDetail( props: StandardDetailProps){
    const classes = useStyles();
    const { t } = useTranslation();

    const [standard, setStandard] = React.useState(null);
    const [internalCodesFull, setInternalCodesFull] = React.useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const deleteThisStandard = () =>{
        deleteStandard(props.id).then(res =>{
            navigate("/standards")
        })
    }

    useEffect(() => {
        findStandardById(props.id).then(res => {
            findAllInternalCodes(null,null).then(icodes => {
                setInternalCodesFull(icodes)
                let tmp = []
                res.internalCodes.forEach(code => {
                    tmp.push(icodes.find(icode => icode.id == code))
                })
                res.internalCodes = tmp
                setStandard(res)
            });
        }).catch((err)=>{navigate("/404")})
    },[]);

    return (
        <Paper className={classes.root} style={props.style}>
            {(standard!= null && internalCodesFull!=null) ?
            <Card variant="outlined">
                <Header title={t("STANDARDDETAIL")} titleTypographyProps={{variant:"h5", align:"center"}}/>
                    <CardContent>
                        <Typography className={classes.fieldTitle} variant="h6">
                            {t("NAME")}
                        </Typography>
                        <Typography variant="body1" className={classes.fieldContent}>
                            {standard.name}
                        </Typography>
                        <Typography className={classes.fieldTitle} variant="h6">
                            {t("ENGLISH_NAME")}
                        </Typography>
                        <Typography variant="body1" className={classes.fieldContent}>
                            {standard.englishName}
                        </Typography>
                        <Typography className={classes.fieldTitle} variant="h6">
                            {t("INTERNALCODES")}
                        </Typography>
                            <Table style={{width: 400}} aria-label={t("INTERNALCODES")}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: "bold", width: 200, padding: 5}}>{t("NAME")}</TableCell>
                                        <TableCell style={{ fontWeight: "bold", width: 200, padding: 5}}>{t("TYPE")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {standard.internalCodes.map(code => (
                                        <TableRow key={code.id}>
                                            <TableCell style={{ width: 200, padding: 5 }}>{code.name}</TableCell>
                                            <TableCell style={{ width: 200, padding: 5 }}>{code.type}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        <br/>
                        <Typography className={classes.fieldTitle} variant="h6">
                            {t("STANDARDCODE")}
                        </Typography>
                        <Typography variant="body1" className={classes.fieldContent}>
                            {standard.code}
                        </Typography>
                        <Typography className={classes.fieldTitle} variant="h6">
                            {t("VALIDITY")}
                        </Typography>
                        <Typography variant="body1" className={classes.fieldContent}>
                            {standard.validity}
                        </Typography>
                        <Typography className={classes.fieldTitle} variant="h6">
                            {t("HARMONIZED")}
                        </Typography>
                        <Typography variant="body1" className={classes.fieldContent}>
                            {t(standard.harmonized)}
                        </Typography>
                        <Typography className={classes.fieldTitle} variant="h6">
                            {t("CSNNUMBER")}
                        </Typography>
                        <Typography variant="body1" className={classes.fieldContent}>
                            {standard.catalogNumber}
                        </Typography>
                        <Typography className={classes.fieldTitle} variant="h6">
                            {t("STANDARDURL")}
                        </Typography>
                        <Typography variant="body1" className={classes.fieldContent}>
                            <a target="_blank" href={standard.url}>{standard.url}</a>
                        </Typography>
                        <Typography className={classes.fieldTitle} variant="h6">
                            {t("ANNOTATIONTEXT")}
                        </Typography>
                        <Typography variant="body1" className={classes.fieldContent}>
                            {(standard.annotation==null || standard.annotation.length==0) ? t("NOANNOTATION") : standard.annotation}
                        </Typography>
                    </CardContent>

                <CardActions className={classes.cardActions}>
                    <ButtonStyled
                        text={t("DELETE")}
                        onClick={()=>setDeleteDialogOpen(true)}/>
                    <ConfirmDialog
                        title={t("DELETESTANDARD")}
                        open={deleteDialogOpen}
                        onClose={()=>setDeleteDialogOpen(false)}
                        onConfirm={deleteThisStandard}
                    >
                        {t("DELETESTANDARDCONFIRM",{standardName: standard.name})}
                    </ConfirmDialog>
                    <ButtonStyled
                        text={t("EDIT")}
                        onClick={() => navigate("/standards/"+props.id+"/edit")}/>
                </CardActions>
            </Card>
            : <div/>}
        </Paper>
    );
}
