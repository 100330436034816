import React from 'react';
import Layout from '../../../components/Layout';

import StandardDetail from "../../../components/standards/StandardDetail";

export default function StandardDetailPage( props ) {

    return (
        <Layout allowAnonymous={false}>
            <StandardDetail style={{minWidth:700, width:"60%", marginLeft: "auto", marginRight: "auto"}} id={props.params.standardId}/>
        </Layout>
    )
}